import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Log from './Log'

function DevLogs(props) {

    const query = useStaticQuery(graphql`
    query{
        allContentfulLog( 
            filter: { 
                category : { 
                    eq: "dev" 
                } 
            },
            limit: 3
        ){
            edges{
                node{
                    title
                    slug
                    publishedDate(formatString: "MM/DD/YYYY")
                }
            }
        }
    }
    `)

    if( query.allContentfulLog.edges.length > 0){
        return (
            <Log className={ props.className } data={ query.allContentfulLog.edges }/>
        )
    }
    else{
        return null;
    }
}

export default DevLogs
