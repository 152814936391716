import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Log from './Log';

function ComingSoonLogs(props) {
    const query = useStaticQuery(graphql`
    query{
        allContentfulLog( 
            filter: { 
                category : { 
                    eq: "coming-soon" 
                } 
            },
            limit: 3
        ){
            edges{
                node{
                    title
                    slug
                    publishedDate(formatString: "MM/DD/YYYY")
                }
            }
        }
    }
    `)

    //console.log( query.allContentfulLog.edges.length )
    if( query.allContentfulLog.edges.length > 0){
        return (
            <Log className={ props.className } data={ query.allContentfulLog.edges }/>
        )
    }
    else{
        return null
    }
    
}

export default ComingSoonLogs
