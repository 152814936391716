import React from 'react'
import ComingSoonLogs from './ComingSoonLogs'
import DevLogs from './DevLogs'
import UpdateLogs from './UpdateLogs'

function LogSideBar(props) {
    return (
        <div className={ props.className }>
            <h6>Looking for something else?</h6>
            <div className="coming-soon">
                <h6>COMING SOON</h6>
                <ComingSoonLogs />
            </div>
            <div className="update">
                <h6>UPDATES</h6>
                <UpdateLogs />
            </div>
            <div className="update-logs">
                <h6>DEV STUFF</h6>
                <DevLogs />
            </div>
        </div>
    )
}

export default LogSideBar
