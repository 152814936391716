import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Log from './Log'

function UpdateLogs(props) {
    const query = useStaticQuery(graphql`
    query{
        allContentfulLog( 
            filter: { 
                category : { 
                    eq: "update" 
                } 
            }
          	sort : {
              fields : publishedDate,
              order: DESC
            }
        ){
            edges{
                node{
                    title
                    slug
                    publishedDate(formatString: "MM/DD/YYYY")
                }
            }
        }
    }
    `)

    //console.log( query.allContentfulLog )
    if( query.allContentfulLog.edges.length > 0){
        return (
            <Log className={ props.className } data={ query.allContentfulLog.edges }/>
        )
    }
    else{
        return null
    }
}

export default UpdateLogs
