import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import UseContentfulImage from '../components/UseContentfulImage'

import * as logStyles from '../styles/log.module.scss'
import LogSideBar from '../components/LogSideBar'

export const query = graphql`
query($slug: String!){
    contentfulLog( slug: { eq: $slug} ){
      title
      content{
        raw
      }
      publishedDate(formatString: "MM/DD/YYYY")
      relatedEntry{
        title
        slug
      }
    }
  }
`

function LogTemplate(props) {

    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {
                const asset = UseContentfulImage(node.data.target.sys.id)

                if (asset) {
                    const url = asset.node.file.url
                    const alt = asset.node.title
                    return (
                        <img src={ url } alt={ alt } />
                    )
                }
            }
        }
    }

    return (
        <Layout>
            <section className={ logStyles.log }>
                <div className={ logStyles.content }>
                    <p><Link to="/logs">logs</Link> / {props.data.contentfulLog.relatedEntry ? <><Link to={"../../projects/" + props.data.contentfulLog.relatedEntry.slug}>{props.data.contentfulLog.relatedEntry.title}</Link> /</> : ""} { props.data.contentfulLog.title }</p>
                    <h2>{ props.data.contentfulLog.title }</h2>
                    <span>{ props.data.contentfulLog.publishedDate }</span>
                    { documentToReactComponents(JSON.parse(props.data.contentfulLog.content.raw), options) }
                </div>
                <LogSideBar className={ logStyles.sidebar }/>
            </section>
        </Layout>
    )
}

export default LogTemplate
